.App {
  text-align: center;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.message-container {
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}

.message {
  display: flex;
  width: 100%;
  text-align: center;
  color: black;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
}

.additional-message {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 1.2em;
}